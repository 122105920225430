import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";
import { getFormattedDate } from "../../utils/getDate";
import { getTodayDate } from "../../utils/getYear";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Rating and reviews</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            General
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">4625</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">इशांत मिन्हास</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">{getFormattedDate("today")}</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            हे भगवान, सबसे अच्छा कैसीनो जो मैंने कभी देखा है! मैंने खेलना शुरू
            किया और तुरंत बोनस मिल गया। मैं काफी समय से इतना भाग्यशाली नहीं रहा,
            इतने अच्छे प्रोजेक्ट के लिए धन्यवाद!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">क्रिकेट {getTodayDate()}</p>
            <p className="review__description">
              इशांत मिन्हास, आपकी प्रतिक्रिया के लिए धन्यवाद! हमारे लिए यह बहुत
              महत्वपूर्ण है कि हमारे ग्राहक हमारे प्रोजेक्ट का आनंद लें। हमारी
              टीम यह सुनिश्चित करने के लिए हर संभव प्रयास करती है कि आपको हमारे
              उत्पाद से सबसे अधिक लाभ मिले!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">विष्णु नंदा</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">{getFormattedDate("yesterday")}</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            लोगों ने बार-बार शीर्ष परियोजनाएं बनाई हैं जिनका कोई एनालॉग नहीं है।
            प्रतिस्पर्धियों में सर्वश्रेष्ठ. केवल मनोरंजन के लिए, मैंने 500
            रुपये का टॉपअप कराया और मेरे बैंक कार्ड पर 4300 रुपये प्राप्त हुए!
            बढ़िया प्रोजेक्ट!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">क्रिकेट {getTodayDate()}</p>
            <p className="review__description">
              विष्णु नंदा,आपकी सकारात्मक राय के लिए धन्यवाद। हम अपने डिज़ाइन को
              बेहतर बनाने के लिए लगातार काम कर रहे हैं, हमें बहुत खुशी है कि
              आपको यह पसंद आया!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">शिरीष बसाक</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">{getFormattedDate("2 days ago")}</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            आमतौर पर मैं इन सभी कैसिनो पर विश्वास नहीं करता! लेकिन यह ऐप तो बस
            कुछ है! मैंने खेल में 460 रुपये का निवेश किया और 15 मिनट में 5000
            रुपये कमाए। मैं खेलना और पैसा कमाना जारी रखूंगा!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">क्रिकेट {getTodayDate()}</p>
            <p className="review__description">
              शिरीष बसाक, आपकी बेहतरीन समीक्षा के लिए धन्यवाद! खेलें और आनंद
              लें!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">एकबाल पाण्डेय</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">{getFormattedDate("4 days ago")}</p>
                </div>
                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
              </div>
              <p className="item__text">
                मुझे उम्मीद नहीं थी कि लोग इतना अच्छा प्रोजेक्ट करेंगे! मेरा
                सम्मान! मेरे जीवन का सबसे उदार कैसीनो! मुझे सुखद आश्चर्य हुआ)
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">क्रिकेट {getTodayDate()}</p>
                <p className="review__description">
                  एकबाल पाण्डेय,आपकी राय के लिए धन्यवाद। हमारा प्रोजेक्ट पूरी
                  तरह से लोगों को भावनाएं देने के लिए बनाया गया था। हम आपको
                  संतुष्ट करने की पूरी कोशिश करेंगे! आपको कामयाबी मिले!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                Kazino!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее Kazino в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Hide reviews" : "All reviews"}
      </button>
    </>
  );
};

export function getFormattedDate(option) {
  const today = new Date();
  let daysOffset = 0;

  // Extract the number of days from the input
  if (option === "today") {
    daysOffset = 0;
  } else if (option === "yesterday") {
    daysOffset = 1;
  } else if (option.match(/^(\d) days ago$/)) {
    daysOffset = parseInt(option, 10);
    if (daysOffset < 2 || daysOffset > 5) {
      throw new Error(
        "Invalid option. Use 'today', 'yesterday', or 'N days ago' (N = 2 to 5)."
      );
    }
  } else {
    throw new Error(
      "Invalid option. Use 'today', 'yesterday', or 'N days ago' (N = 2 to 5)."
    );
  }

  // Adjust the date
  today.setDate(today.getDate() - daysOffset);

  // Ensure proper formatting
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = today.getFullYear();

  return `${day}.${month}.${year}`;
}

export const New = () => {
  return (
    <div className="new">
      <p className="new__title">खबर क्या है?</p>
      <p className="new__description">
        "बोनस और प्रमोशन" अनुभाग अद्यतन कर दिया गया है
      </p>
      <p className="new__description">
        निकटतम शाखा, एटीएम या टर्मिनल का स्थान मानचित्र में जोड़ा गया है।
      </p>
      <p className="new__description">
        फेस आईडी और टच आईडी के लिए समर्थन जोड़ा गया।
      </p>
      <p className="new__description">
        कुछ एंड्रॉइड 10 उपयोगकर्ताओं द्वारा अनुभव की जा रही पुश अधिसूचना समस्या
        को ठीक करता है।
      </p>
      <p className="new__description">
        उस समस्या को ठीक किया गया जहां एंड्रॉइड उपयोगकर्ताओं के लिए सेटिंग्स में
        टेक्स्ट बहुत छोटा था।
      </p>
    </div>
  );
};
